import React from 'react';
import {useArticlesData} from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_9} from "shared/Types/articles";
import {Author, Hero, Image, Layout, Text, TextImage} from "components/BlogLayout";
import SEO from "components/seo";

const Article_9 = () => {
  const data = useArticlesData(ARTICLE_9);

  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.ogImage}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <Image data={data.images.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <Text data={data.text.fifth}/>
      <TextImage data={data.text.sixth} image={data.images.second}/>
      <Author data={data.authors}/>
    </Layout>
  );
}

export default Article_9;
